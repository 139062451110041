import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingBoxWrapper = styled.div`
width: 100px;
height: 80px;
text-align: center;
padding: 10px 5px;
box-shadow: 0 0px 16px 2px #ccc;
border-radius: 3px;
position: absolute;
top: 50%;
left: 50%;
margin-top: -60px;
margin-left: -50px;
`;

const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

class LoadingBox extends React.Component {
    render() {
        return (
            <LoadingBoxWrapper>
                <Spin indicator={antIcon} />
                <p>Loading...</p>
            </LoadingBoxWrapper>
        );
    }
}

export default LoadingBox;