import React from 'react';
import { connect } from 'react-redux';

import { Route, Switch } from 'react-router-dom';

import NotLoggedInClientView from './NotLoggedInClientView';
import LoggedInClientView from './LoggedInClientView';
import Login from './Login';
import ClientFooter from './ClientFooter';
import BookingPreparation from './BookingPreparation';
import PaypalPayment from './PaypalPayment';
import { PayPalButton } from 'react-paypal-button-v2';
import PaymentSuccess from './PaymentSuccess';

class ClientView extends React.Component {
    render() {
        let view;
        //if (!this.props.isLoggedIn) {
            view = (
                <React.Fragment>
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Route path="/dat-lich" exact component={BookingPreparation} />
                        <Route path='/thanh-toan' exact component={PaypalPayment} />
                        <Route path='/thanh-cong' exact component={PaymentSuccess} />
                        <Route path="/" component={NotLoggedInClientView} />
                    </Switch>
                </React.Fragment>
            );
        //} 
        /*
        else {
            view = (
                <React.Fragment>
                    <Switch>
                        <Route path="/lich-tu-van" exact component={LoggedInClientView} />
                        <Route path="/dat-lich" exact component={BookingPreparation} />
                        <Route path='/thanh-toan' exact component={PaypalPayment} />
                        <Route path='/thanh-cong' exact component={PaymentSuccess} />
                        <Route path="/" component={LoggedInClientView} />
                    </Switch>
                </React.Fragment>
            );
        }
        */
        return (
            <React.Fragment>
                {view}
                <div style={{display: 'none'}}>
                    <PayPalButton
                        amount="0.01" />
                </div>
                <ClientFooter />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        user: state.user
    }
}

export default connect(mapStateToProps)(ClientView);