// import React from 'react';
import styled from 'styled-components';

const ShadowBox = styled.div`
box-shadow: 0 1px 3px 1px #ccc;
padding: 10px 20px;
background-color: #fff;
border-radius: 8px;
`

export default ShadowBox;