//import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
width: 100%;
padding: 10px;
@media (min-width: 1024px){
    width: 1000px;
    padding: 0;
    margin: 0 auto;
}
`;

export default Container;