import React from 'react';
import { connect } from 'react-redux';
import { PayPalButton } from 'react-paypal-button-v2';
import styled from 'styled-components';
import ClientHeader from './ClientHeader';
import Container from './Container';
import { ClientViewWraper, CoreWraper } from './CommonUI';
import ShadowBox from './ShadowBox';
import { message } from 'antd';
import axios from 'axios';

const PaypalButtonWrapper = styled.div`
    width: 100%;
    padding: 0;
    @media (min-width: 768px){
        width: 500px;
        margin: 0 auto;
        padding: 10px;
    }
`;

const SpacePad = styled.div`
    height: 10px;
    @media (min-width: 768px){
        height: 300px;
    }
`;

class PaypalPayment extends React.Component {

    onPaypalSuccess = (details, data) => {
        console.log('details: ', details);
        console.log('data', data);
        this.sendPaymentSuccess(details);
        // this.props.history.push('/');
    }

    sendPaymentSuccess = (details) => {
        const {orderId} = this.props;
        const params = new FormData();
        params.append("orderID", orderId);
        params.append("paypalPaymentId", details.id);
        params.append("status", details.status);
        const payer = details.payer;
        params.append("payerEmail", payer['email_address']);
        params.append("payerID", payer['payer_id']);
        const address = payer.address;
        const fullAddress = address.address_line_1 + ", " + address.address_line_2 + ", "
                            + address.admin_area_2 + ", " + address.admin_area_1 
                            + ", " + address.postal_code
                            + ", " + address.country_code;
        params.append("payerAddress", fullAddress);
        const name = payer.name;
        params.append("payerName", name.surname + " " + name.given_name);

        let phoneNumber = payer.phone && payer.phone.phone_number && payer.phone.phone_number.national_number;
        // phoneNumber = phoneNumber && payer.phone.phone_number.national_number;
        // phoneNumber = "393880607";
        params.append("payerPhone", phoneNumber);
        const purchaseUnits = details.purchase_units;
        const pu = purchaseUnits[0];
        params.append("amount", pu.amount.value);
        params.append("currencyCode", pu.amount.currency_code);
        const payee = pu.payee;
        params.append("payeeEmail", payee.email_address);
        params.append("merchantId", payee.merchant_id);

        axios.post('/api/paymentSuccess.php', params)
        .then(resp => {
            console.log('paymentSuccess: ', resp.data);
            message.success('Giao dịch thành công');   
            this.props.history.push('/thanh-cong'); 
        })
        .catch(e => {
            message.error('Bạn đã thanh toán thành công nhưng chúng tôi không thể ghi nhận kết quả vào hệ thống');
        })
        
    }

    onPaypalCancel = data => {
        console.log("Cancelled: ", data);
        message.warn('Bạn đã hủy giao dịch! Vui lòng thanh toán để giữ chỗ.');
    }

    onPaypalApprove = (data, actions) => {
        console.log("Approved: data: ", data, "\n action: ", actions);
    }

    onPaypalError = error => {
        console.error(error);
        message.error('Rất tiếc, đã có lỗi xảy ra và chúng tôi không thể ghi nhận được thanh toán của bạn. Vui lòng thử lại.');
    }

    render() {
        const paypalOptions = {
            clientId: 'ARw2E8576y8GVbpUx20z1brV10XudCqLzuCckZh3ugbSqh4PWRXBAOvXsIrh3SjwjUVCba1o5XMjEt_T'
        }
        const { orderId } = this.props;
        //console.log("[PaypalPayment] render(): props: ", this.props);
        const consultantInfo = this.props.selectedConsultantInfo;
        //console.log("[PaypalPayment] render(): consultantInfo: ", consultantInfo);
        
        const dataIsReady = orderId && consultantInfo;
        let amount = consultantInfo.price;
        if(Math.round(amount) === 0.0){
            amount = 0.01;
        }
        return (
            <ClientViewWraper>
                <ClientHeader />
                <CoreWraper>
                    <Container>
                        <ShadowBox>

                            <PaypalButtonWrapper>
                                <h3 style={{ textAlign: 'center' }}>Chỉ còn 1 bước nữa thôi</h3>
                                <p>
                                    Thông tin đặt lịch của bạn đã được chúng tôi ghi nhận thành công.
                                    Bước tiếp theo bạn cần làm là tiến hành thanh toán để đảm bảo được giữ chỗ.
                                </p>
                                {
                                    (dataIsReady) ? 
                                    <PayPalButton
                                    amount={amount}
                                    onSuccess={(details, data) => this.onPaypalSuccess(details, data)}
                                    onCancel={data => this.onPaypalCancel(data)}
                                    onApprove={(data, actions) => this.onPaypalApprove(data, actions)}
                                    onError={(error) => this.onPaypalError(error)}
                                    options={paypalOptions} />
                                    : <p>Oops! Something went wrong!</p>
                                }
                                
                            </PaypalButtonWrapper>
                            <SpacePad />
                        </ShadowBox>
                        <div style={{height: '20px'}}></div>
                    </Container>
                </CoreWraper>
            </ClientViewWraper>
        );
    }
}

const mapStateToProps = state => {
    return {
        orderId: state.orderId,
        selectedConsultantInfo: state.selectedConsultantInfo,
    }
}

export default connect(mapStateToProps)(PaypalPayment);