import React from 'react';
import { connect } from 'react-redux';
import ClientHeader from './ClientHeader';
import Container from './Container';
import { ClientViewWraper, CoreWraper } from './CommonUI';
import ShadowBox from './ShadowBox';
import { Result, Button } from 'antd';
import styled from 'styled-components';

const SpacePad = styled.div`
    height: 10px;
    @media (min-width: 768px){
        height: 300px;
    }
`;



class PaymentSucess extends React.Component {
    gotoHomePage = () => {
        this.props.history.push("/");
    }
    render() {
        const {orderId} = this.props;
        return (
            <ClientViewWraper>
                <ClientHeader />
                <CoreWraper>
                    <Container>
                        <ShadowBox>
                            <Result 
                            status="success"
                            title="Đặt lịch hoàn tất thành công"
                            subTitle={'Mã giao dịch: ' + orderId}
                            extra={
                                <Button type='primary' onClick={this.gotoHomePage}>
                                    Về trang chủ
                                </Button>
                            }/>
                        <SpacePad/>
                        </ShadowBox>
                        <div style={{height: '20px'}}></div>
                    </Container>
                </CoreWraper>
            </ClientViewWraper>
        );
    }
}

const mapStateToProps = state => {
    return {
        orderId: state.orderId
    }
}

export default connect(mapStateToProps)(PaymentSucess);

