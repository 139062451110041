import React, { Component } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import BackDrop from './BackDrop';

const LoadingBoxWrapper = styled.div`
    width: 100px;
    height: 80px;
    text-align: center;
    padding: 10px 5px;
    box-shadow: 0 0px 16px 2px #0a0a0a;
    background-color: #fafafa;
    border-radius: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -60px;
    margin-left: -50px;
`;

const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

class LoadingModal extends Component {
    render() {
        if(this.props.show){
            return (
                <BackDrop>
                    <LoadingBoxWrapper>
                        <Spin indicator={antIcon} />
                        <p>Loading...</p>
                    </LoadingBoxWrapper>
                </BackDrop>
            );
        } else {
            return null;
        }
        
    }
}

export default LoadingModal;