import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { connect } from 'react-redux';
import * as actions from '../store/actions';

import ShadowBox from './ShadowBox';
import ConsultantInfo from './ConsultantInfo';

const Wrapper = styled(ShadowBox)`
    display: block;
    margin: 20px 0;
    padding: 3px;
    @media (min-width: 768px){
        justify-content: start;
        align-items: center;
    }
`;

const AvatarWrapper = styled.div`
    display: inline-block;
    padding: 10px;
    width: 150px;
    height: 150px;
    @media(min-width: 768px){
        
    }
`;

const AvatarImage = styled.img.attrs(
    props => ({ src: props.src })
)`
    border-radius: 75px;
    width: 130px;
    height: 130px;
`;

const BlockWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const BlockWrapperTabletUp = styled(BlockWrapper)`
    display: none;
    @media(min-width: 768px){
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
`;

const BlockWrapperMobile = styled(BlockWrapper)`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
    @media(min-width: 768px){
        display: none;
    }
`;

const BlockItemWrapper = styled.div.attrs(props => ({
    blockId: props.blockId
}))`
    box-shadow: 0 1px 2px 0px rgba(0,0,0,0.2);
    border-radius: 6px;
    padding: 6px 9px;
    margin: 3px;
    min-width: 126px;
    background-color: #fff;
    cursor: pointer;
`;

const InfoAndBlockWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 768px){
        justify-content: start;
        align-items: flex-start;
        padding-top: 10px;
        padding-bottom: 16px;
    }
`;

class BlockItem extends React.Component {

    getTime = (dateTime) => {
        let mm = moment(dateTime, 'YYYY-MM-DD HH:mm:ss');
        return mm.format('HH:mm A')
    }


    render() {
        // console.log("[BlockItem] props: ", this.props);
        // Props: {block: "1", timeStart: "2020-01-16 08:00:00", timeEnd: "2020-01-16 08:45:00", status: "0"}
        const { block, consultantInfo } = this.props;
        let timeStart = this.getTime(this.props.timeStart);
        let timeEnd = this.getTime(this.props.timeEnd);
        return (
            <BlockItemWrapper
                blockId={block.id}
                onClick={() => this.props.onBlockClicked(block, consultantInfo)}
                available={this.props.status === '1'}>
                {timeStart} - {timeEnd}
            </BlockItemWrapper>
        );
    }
}

class AvailableConsultantDayBlock extends React.Component {

    onBlockClicked = (blockId, consultantInfo) => {
        console.log("[AvailableConsultantDayBlock]: block clicked: ", blockId)
        this.props.notifyBlockClicked(blockId, consultantInfo);
        this.props.history.push('/dat-lich');
    }

    render() {
        console.log("[AvailableConsultantDayBlock] render(): props: ", this.props);

        // consultancyType: "Tư vấn định cư"
        // consultantName: "Nguyễn Công Quyết"
        // rating: "5"
        // ratingCount: "150"
        // price: "60"
        // date: "2020-03-02"
        // block: ....

        let consultantInfo = {
            consultancyType: this.props.consultancyType,
            consultantName: this.props.consultantName,
            rating: this.props.rating,
            ratingCount: this.props.ratingCount,
            price: this.props.price,
            date: this.props.date
        }

        // let blocksLaptopUp;
        // let blocksMobile;
        let blocks = this.props.block.map((b, i) => {
            // console.log("Block["+i+"]: ", b);
            if (Number(b.status) === 0 || Number(b.status) === 1) {
                return (
                    <BlockItem key={i} {...b}
                        block={b}
                        consultantInfo={consultantInfo}
                        onBlockClicked={this.onBlockClicked} />
                )
            } else {
                return null;
            }

        })
        let images = [
            'http://dtdauto.zamio.net/assets/darktheme/images/our_team_7.jpg',
            'http://dtdauto.zamio.net/assets/darktheme/images/our_team_9.jpg'
        ]

        let rand = Math.floor(Math.random() * 10);
        let imgSrc;
        // console.log("Random: ", rand);
        if (rand % 2 === 0) {
            imgSrc = images[0];
        } else {
            imgSrc = images[1];
        }
        return (

            <Wrapper>
                <div style={{ display: 'flex' }}>
                    <AvatarWrapper>
                        <AvatarImage src={imgSrc} />
                    </AvatarWrapper>
                    <InfoAndBlockWrapper>
                        <ConsultantInfo {...consultantInfo} />
                        <BlockWrapperTabletUp>
                            {blocks}
                        </BlockWrapperTabletUp>

                    </InfoAndBlockWrapper>
                </div>

                <BlockWrapperMobile>
                    {blocks}
                </BlockWrapperMobile>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        notifyBlockClicked: (block, consultantInfo) => {
            dispatch({
                type: actions.SELECT_BLOCK,
                payload: { 
                    block: block,
                    consultantInfo: consultantInfo
                }
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailableConsultantDayBlock);