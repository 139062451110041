import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { message } from 'antd';

import Container from './Container';
import MyLabel from './MyLabel';
import * as sizes from './sizes';
import logo from '../assets/logo.png';
import thorben from '../assets/thorben.jpeg';

import * as actions from '../store/actions';


const ClientHeaderWrapper = styled.div`
    width: 100%;
    border-bottom: 1px solid #ccc;
`;

const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    box-sizing: border-box;
`;

const LogoImageWrapper = styled.div`
    width: 230px;
    height: 100%;
    display: flex;
    align-items: center;
    @media(min-width: ${sizes.laptop}){
        display: flex;
    }
`;

const BarsWrapper = styled.div`
    width: 50px;
    height: 100%;
    font-size: 1.5em;
    display: ${props => (props.display)};
    align-items: center;
    justify-content: center;
    @media(min-width: ${sizes.laptop}){
        display: none;
    }
`;

const LoginButton = styled.div`
    display: inline-block;
    border: 1px solid #ccc;
    padding: 3px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    box-shadow: 0 1px 1px 0px rgba(0,0,0,0.3);
    transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    :hover{
        box-shadow: 0 1px 5px 0px rgba(0,0,0,0.3);
    }
`;

const AvatarAndNameWrapper = styled.div`
    display: none;
    @media (min-width: 768px){
        display: inline-block;
    }
`;

const UserName = styled(MyLabel)`
    margin-right: 10px
`;

const MenuNavLink = styled.a.attrs(props => ({
    to: props.to,
    onClick: props.onClick
}))`
    margin-right: 10px;
    color: #0a0e17;
    font-size: 15px;
    font-weight: 500;
    height: 62px;
    display: inline-block;
    line-height: 62px;
    padding-left: 8px;
    padding-right: 8px;
    :hover {
        background-color: #f3f3f3;
        color: #0a0e17;
    }
`;

const UserInfo = styled.div`
    margin-right: 10px;
    color: #0a0e17;
    font-size: 15px;
    font-weight: 500;
    height: 62px;
    display: inline-block;
    line-height: 62px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
    :hover {
        background-color: #f3f3f3;
        color: #0a0e17;
    }
`;

const DropDown = styled.div`
    position: absolute;
    display: none;
    margin-left: -8px;
    width: 200px;
    background-color: #fff;
    box-shadow: 0 3px 3px 0px #ccc;
    padding-top: 3px;
    ${UserInfo}:hover & {
        display: block;
    }
`;

const DropDownItem = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    height: 40px;
    line-height: 40px;
    font-weight: 400;
    cursor: pointer;
    :hover {
        background-color: #f3f3f3;
    }
`;

class ClientHeader extends React.Component {

    onMenuItemClicked = (evt) => {
        const { isLoggedIn } = this.props;
        if (!isLoggedIn) {
            evt.preventDefault();
            message.info('Vui lòng đăng nhập để sử dụng tính năng này');
        }
        // console.log("EVT: ", evt);
    }

    logout = () => {
        console.log("Logging out");
        localStorage.removeItem('accessToken');
        this.props.doLogout();
    }

    render() {
        let barsDisplay = this.props.isLoggedIn ? 'flex' : 'none';
        let loginButton = this.props.isLoggedIn ? null
            : <LoginButton>
                <NavLink to="/login">Đăng nhập</NavLink>
            </LoginButton>;

        let userAvatarImage = null;
        if (this.props.isLoggedIn) {
            let userAvatarSrc = this.props.user.avatar;
            if (!userAvatarSrc) {
                userAvatarSrc = thorben;
            }

            userAvatarImage =
                <img src={userAvatarSrc} alt="User Avatar"
                    style={{ width: '40px', height: '40px', borderRadius: '25px' }} />
        }
        let userFullName = this.props.isLoggedIn ?
            <UserName>{this.props.user.fullName}</UserName>
            : null;

        let avatarAndName = (
            <AvatarAndNameWrapper>
                <MenuNavLink to='/lich-su-tu-van' onClick={this.onMenuItemClicked}>Lịch sử tư vấn</MenuNavLink>
                <MenuNavLink to='/' onClick={this.onMenuItemClicked}>Lịch sử đặt lịch</MenuNavLink>
                <MenuNavLink to='/' onClick={this.onMenuItemClicked}>Thanh toán</MenuNavLink>
                {
                    this.props.isLoggedIn ? (
                        <UserInfo>
                            {userFullName}
                            {userAvatarImage}
                            <DropDown>
                                <DropDownItem>
                                    Thông tin cá nhân
                                </DropDownItem>
                                <DropDownItem onClick={() => this.logout()}>
                                    Đăng xuất
                                </DropDownItem>
                            </DropDown>
                        </UserInfo>
                    )
                        : null
                }


            </AvatarAndNameWrapper>
        )
        return (
            <ClientHeaderWrapper>
                <Container>
                    <Header>
                        <LogoImageWrapper>
                            <BarsWrapper display={barsDisplay}>
                                <FontAwesomeIcon icon={faBars} />
                            </BarsWrapper>
                            <NavLink to="/">
                                <img src={logo} alt="LOGO" style={{ width: '180px', height: '57px' }} />
                            </NavLink>

                        </LogoImageWrapper>
                        <div className="aa">
                            {avatarAndName}
                            {loginButton}
                        </div>

                    </Header>
                </Container>
            </ClientHeaderWrapper>
        )
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: state.isLoggedIn,
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        doLogout: () => dispatch({type: actions.LOGOUT})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientHeader);