import React from 'react';
import styled from 'styled-components';

import Container from './Container';

const Wrapper = styled.div`
    padding: 30px 0;
    background-color: #505050;
    color: #e8e8e8;
`;

const Center = styled.div`
    text-align: center;
`;

class ClientFooter extends React.Component {
    render() {
        return (
            <Wrapper>
                <Container>
                    <Center>
                        Bản quyền 2020 © Du học Rightway.
                    </Center>
                </Container>
            </Wrapper>
        );
    }
}

export default ClientFooter;

