// import React from 'react';
import styled from 'styled-components';
import * as colors from './colors';

export const ClientViewWraper = styled.div`
    width: 100%;
`;

export const CoreWraper = styled.div`
    width: 100%;
    background-color: ${colors.colorLightPurple};
`;