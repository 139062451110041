import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Table, message, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

/*
const ds = [
    {
        consultantName: 'Nguyễn Công Quyết',
        consultancyType: 'Tư vấn định cư',
        date: '2020-03-05',
        block: [
            {
                "block": "1",
                "timeStart": "2020-04-03 08:00:00",
                "timeEnd": "2020-04-03 08:45:00",
                "status": "0"
            }
        ],
        price: '85',
        rating: 5.0,
        ratingCount: 150,
    }
]
*/

const columns = [
    {
        title: 'STT',
        render: (t, r, i) => (i + 1)
    },
    {
        title: 'Tư vấn viên',
        dataIndex: 'consultantName',
        key: 'consultantName'
    },
    {
        title: 'Loại tư vấn',
        dataIndex: 'consultancyType',
        key: 'consultancyType'
    },
    {
        title: 'Ngày',
        dataIndex: 'date',
        key: 'date'
    },
    // {
    //     title: 'Số khung giờ',
    //     render: (text, record, index) => {
    //         return '3'
    //     }
    // },
    // {
    //     title: 'Còn lại',
    //     render: (text, record, index) => {
    //         return '5'
    //     }
    // },
    {
        title: 'Giá tiền',
        render: (text, record, index) => {
            return '$' + record.price
        }
    },
    {
        title: 'Đánh giá',
        render: (text, record, index) => {
            return <span>
                <b>{record.rating}</b> <span> ({record.ratingCount} lượt đánh giá)</span>
            </span>
        }
    }
]

const Controls = styled.div`
    padding: 10px 0;
`;

class Booking extends React.Component {

    state = {
        data: []
    }

    componentDidMount = () => {
        axios.get('/api/getListBookingforStudent.php', {})
            .then(resp => {
                console.log("Resp data: ", resp.data.data);
                this.setState({ data: resp.data.data })
            })
            .catch(e => {
                console.error(e);
                message.error("Đã có lỗi xảy ra. Vui lòng thử lại!");
            })
    }

    onAddNewButtonClicked = (evt) => {
        console.log("AddNew button clicked: ", evt);
        this.props.history.push("/add-booking");
    }

    render() {
        return (
            <React.Fragment>
                <h2>LỊCH TƯ VẤN</h2>
                <Controls>
                    <Button type='primary' icon={<PlusCircleOutlined />} onClick={this.onAddNewButtonClicked}>
                        Thêm mới
                    </Button>
                </Controls>
                <Table
                    rowKey={row => row.consultantName + row.date}
                    dataSource={this.state.data}
                    columns={columns}
                />
            </React.Fragment>
        );
    }
}

export default Booking;