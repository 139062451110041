import React from 'react';
import axios from 'axios';
import { Table, Modal, Row, Col } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons'
import { baseURL } from '../../App';
import Loading from '../ui/Loading';
import moment from 'moment';
import styled from 'styled-components';

const ModalLabel = styled.span`
    text-align: right;
    display: inline-block;
    width: 100%;
    padding-right: 5px;
    font-weight: 500;
`;


class Booked extends React.Component {
    state = {
        loading: true,
        data: [],
        showModal: false,
        selectedRecord: null,
    }

    componentDidMount = () => {
        axios.get('/api/getOrder.php')
            .then(resp => {
                console.log("getOrder: ", resp.data);
                this.setState({ data: resp.data.data });
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => {
                this.setState({ loading: false });
            })
    }

    showDetails = (evt, record) => {
        evt.preventDefault();
        console.log(record);
        this.setState({ showModal: true, selectedRecord: record });
    }

    getTimeDisplay = (block) => {
        const timeStart = block.timeStart;
        const timeEnd = block.timeEnd;
        const start = moment(timeStart, 'YYYY-MM-DD HH:mm:ss');
        const end = moment(timeEnd, 'YYYY-MM-DD HH:mm:ss');
        return start.format('HH:mm') + " - " + end.format('HH:mm') + " | " + start.format('DD/MM/YYYY');
    }

    render() {

        const columns = [
            {
                title: 'STT',
                render: (text, record, index) => (index + 1)
            },
            {
                title: 'Khách hàng',
                render: (text, record, index) => (
                    <a href="show-details" onClick={(evt) => this.showDetails(evt, record)}>{record.user.fullName}</a>
                )
            },
            {
                title: 'Điện thoại',
                render: (text, record, index) => (record.user.phone)
            },
            {
                title: 'Email',
                render: (text, record, index) => (record.user.email)
            },

            {
                title: 'Thời gian',
                render: (text, record, index) => { return this.getTimeDisplay(record.BlockBooking) }
            },
            {
                title: 'Thanh toán',
                render: (text, record, index) => {
                    if (record.InfoPaypal.status === 'COMPLETED') {
                        return 'Đã thanh toán'
                    } else {
                        if(record.status === '0'){
                            return 'Mới';
                        } else if(record.status === '1'){
                            return 'Chưa thanh toán';
                        }
                        return 'Chưa thanh toán'
                    }
                }
            }
        ]


        const record = this.state.selectedRecord;
        const spanLabel = 4;
        const spanValue = 8;
        return (
            <React.Fragment>
                <h2>KHUNG GIỜ ĐÃ ĐẶT</h2>
                {
                    this.state.loading ? <Loading />
                        : <Table
                            rowKey={row => row.id}
                            pagination={false}
                            dataSource={this.state.data}
                            columns={columns} />
                }
                {
                    record ?
                        <Modal
                            closable={true}
                            footer={null}
                            width={'800px'}
                            onCancel={() => { this.setState({ showModal: false }) }}
                            visible={this.state.showModal}>
                            <div style={{ padding: '10px' }}>
                                <h3>THÔNG TIN CHI TIẾT - GIAO DỊCH: {record.orderID}</h3>
                                <h4>Khách hàng</h4>
                                <Row>
                                    <Col span={spanLabel}><ModalLabel>Tên khách hàng: </ModalLabel></Col>
                                    <Col span={spanValue}>{record.user.fullName}</Col>
                                    <Col span={spanLabel}><ModalLabel>Số điện thoại: </ModalLabel></Col>
                                    <Col span={spanValue}>{record.user.phone}</Col>
                                </Row>
                                <Row>
                                    <Col span={spanLabel}><ModalLabel>Email: </ModalLabel></Col>
                                    <Col span={spanValue}>{record.user.email}</Col>

                                </Row>
                                
                                <Row>
                                    <Col span={spanLabel}>
                                        <ModalLabel>Thời gian đặt hẹn:</ModalLabel>
                                    </Col>
                                    <Col span={spanValue}>
                                        {this.getTimeDisplay(record.BlockBooking)}
                                    </Col>
                                    <Col span={spanLabel}>
                                        <ModalLabel>File CV đính kèm:</ModalLabel>
                                    </Col>
                                    <Col span={spanValue}>
                                        <a href={baseURL + "/api/uploads/" + record.fileCv} target='new'>
                                            <PaperClipOutlined /> Download
                                        </a>
                                    </Col>
                                </Row>

                                <h4>Ghi chú</h4>
                                <Row>
                                    {
                                        record.note ? <p>{record.note}</p>
                                            : 'Không có'
                                    }
                                </Row>
                                <h4>Câu hỏi</h4>
                                <Row>
                                {record.user.address}
                                </Row>

                                <br />
                                <h4>Thông tin thanh toán</h4>
                                <Row>
                                    <Col span={spanLabel}>
                                        <ModalLabel>Mã giao dịch :</ModalLabel>
                                    </Col>
                                    <Col span={spanValue}>
                                        {record.InfoPaypal.paypalPaymentId}
                                    </Col>
                                    <Col span={spanLabel}>
                                        <ModalLabel>Số tiền :</ModalLabel>
                                    </Col>
                                    <Col span={spanValue}>
                                        {record.InfoPaypal.amount}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={spanLabel}>
                                        <ModalLabel>Trạng thái:</ModalLabel>
                                    </Col>
                                    <Col span={spanValue}>
                                        {record.InfoPaypal.status}
                                    </Col>
                                    <Col span={spanLabel}>
                                        <ModalLabel>Loại tiền tệ :</ModalLabel>
                                    </Col>
                                    <Col span={spanValue}>
                                        {record.InfoPaypal.currencyCode}
                                    </Col>
                                </Row>

                                <br />
                                <h4>Người trả tiền</h4>
                                <Row>
                                    <Col span={spanLabel}>
                                        <ModalLabel>Tên :</ModalLabel>
                                    </Col>
                                    <Col span={spanValue}>
                                        {record.InfoPaypal.payerName}
                                    </Col>
                                    <Col span={spanLabel}>
                                        <ModalLabel>Email :</ModalLabel>
                                    </Col>
                                    <Col span={spanValue}>
                                        {record.InfoPaypal.payerEmail}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={spanLabel}>
                                        <ModalLabel>Điện thoại :</ModalLabel>
                                    </Col>
                                    <Col span={spanValue}>
                                        {record.InfoPaypal.payerPhone}
                                    </Col>
                                    <Col span={spanLabel}>
                                        <ModalLabel>Địa chỉ :</ModalLabel>
                                    </Col>
                                    <Col span={spanValue}>
                                        {record.InfoPaypal.payerAddress}
                                    </Col>
                                </Row>

                                <br />
                                <h4>Người nhận tiền</h4>
                                <Row>
                                    <Col span={spanLabel}>
                                        <ModalLabel>Email :</ModalLabel>
                                    </Col>
                                    <Col span={spanValue}>
                                        {record.InfoPaypal.payeeEmail}
                                    </Col>
                                    <Col span={spanLabel}>
                                        <ModalLabel>Mã đại lý :</ModalLabel>
                                    </Col>
                                    <Col span={spanValue}>
                                        {record.InfoPaypal.merchantId}
                                    </Col>
                                </Row>

                            </div>
                        </Modal>
                        : null
                }

            </React.Fragment>
        )
    }
}

export default Booked;