import React from 'react';
import MyLabel from './MyLabel';
import { Rate } from 'antd';
import moment from 'moment';

class ConsultantInfo extends React.Component {
    render() {
        console.log('[ConsultantInfo] props: ', this.props);
        const mmDate = moment(this.props.date);
        const dateStr = mmDate.format('DD/MM/YYYY');
        return (
            <div className="ConsultantInfo">
                <MyLabel>{this.props.consultantName}</MyLabel>
                <p>{this.props.consultancyType}</p>
                <p>{'Ngày ' + dateStr}</p>
                <Rate value={Number(this.props.rating)} /> <p>({this.props.ratingCount} đánh giá)</p>
                <div><b>${this.props.price}/giờ</b></div>
            </div>
        );
    }
}

export default ConsultantInfo;