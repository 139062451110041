import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;

const Wrapper = styled.div`
    width: 40px;
    height: 40px;
    margin: 50px auto;
`;

class Loading extends React.Component {
    render(){
        return(
            <Wrapper>
                <Spin indicator={antIcon}/>
                <p>Loading...</p>
            </Wrapper>
        );
    }
}

export default Loading;