import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 30px;
    background-color: #fff;
`;

const linkBase = {
    width: '100%',
    display: 'block',
    padding: '5px 10px',
    margin: '5px 0',
    color: 'rgba(6, 6, 6, 0.5)',
    fontWeight: '500',
    fontSize: '1.08em'
}

const linkActiveStyle = {
    ...linkBase, 
    borderRight: '2px solid #115FAB',
    color: 'rgba(6, 6, 6, 0.99)',
}

const PaddingFontAwesome = styled(FontAwesomeIcon).attrs(
    props => ({icon: props.icon})
)`
    padding: 0 20px 0 10px;
    width: unset!important;
`;

class LeftMenuContent extends React.Component {
    render() {
        return (
            <Wrapper>
                
                <div>
                    <NavLink to="/lich-tu-van" activeStyle={linkActiveStyle} style={linkBase}>
                        <PaddingFontAwesome icon={faCoffee}/>
                        Lịch tư vấn
                    </NavLink>
                </div>
                <div>
                    <NavLink to="/trang-chu" activeStyle={linkActiveStyle}  style={linkBase}>
                        <PaddingFontAwesome icon={faCoffee}/>
                        Lịch sử đặt lịch
                    </NavLink>
                </div>
                <div>
                    <NavLink to="/thanh-toan" activeStyle={linkActiveStyle}  style={linkBase}>
                        <PaddingFontAwesome icon={faCoffee}/>
                        Thanh toán
                    </NavLink>
                </div>
                <div>
                    <NavLink to="/ho-so-ca-nhan" activeStyle={linkActiveStyle}  style={linkBase}>
                        <PaddingFontAwesome icon={faCoffee}/>
                        Hồ sơ cá nhân
                    </NavLink>
                </div>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

export default connect(mapStateToProps)(LeftMenuContent);