import React from 'react';
import { connect } from 'react-redux';
import {DatePicker} from 'antd';
import styled from 'styled-components';

// import ClientDateBarItems from './ClientDateBarItems';
import MyLabel from './MyLabel';
import * as actions from '../store/actions';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
`;
class ClientDateBar extends React.Component{

    onDateChanged = value => {
        this.props.onDateChanged(value);
    }

    render(){
        return(
            <Wrapper>
                <div>
                    <MyLabel>Lịch tư vấn</MyLabel>
                </div>
                {/* <ClientDateBarItems /> */}
                <DatePicker 
                    onChange={this.onDateChanged}
                    placeholder='Chọn ngày'
                    
                    format='DD/MM/YYYY'/>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedDate: state.selectedDate
    }
}

const mapDispatchToProps = dispatch => {
    return {
        nofifyDateChanged: (dateStr) => {dispatch({type: actions.DATE_CHANGED, payload: dateStr})}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDateBar);