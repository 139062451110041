import React from 'react';
import LoadingBox from './components/LoadingBox';
import 'antd/dist/antd.css';
import axios from 'axios';


import { connect } from 'react-redux';

import * as actions from './store/actions';

import ClientView from './components/ClientView';
import AdminView from './components/admin/AdminView';


class App extends React.Component {

  componentDidMount = () => {
    if(this.props.startingUp){
      this.checkLocalAuthenticationData();
    }
  }

  checkLocalAuthenticationData = () => {
    let accessToken = localStorage.getItem('accessToken');
    let userStr = localStorage.getItem('user');
    if(this.isAccessTokenOk(accessToken) && userStr){
      let user = JSON.parse(userStr);
      this.props.putLocalAuthDataToStore(accessToken, user);
      axios.defaults.headers.common['Authorization'] = accessToken;
    }
    setTimeout(()=> {
      this.props.notifyStartingUpDone();
    }, 1000);
    
  }

  isAccessTokenOk = accessToken => {
    return accessToken !== undefined && accessToken != null;
  }

  render() {
    const loadingBox = <LoadingBox/>;

    let view;
    if (this.props.startingUp) {
      view = loadingBox;
    } else {
      if(this.props.isLoggedIn){
        if(this.props.userType === 'admin' || this.props.userType === 'superAdmin'){
          view = <AdminView />
        } else {
          view = <ClientView />
        }
      } else {
        console.log("User NOT logged in");
        view = <ClientView />
      }
      
    }

    return (
      <div>
        {view}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    startingUp: state.startingUp,
    isLoggedIn: state.isLoggedIn,
    userType: state.userType
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    notifyStartingUpDone: () => dispatch({ type: actions.STARTING_UP_DONE }),
    putLocalAuthDataToStore: (accessToken, user) => dispatch({
      type: actions.PUT_LOCAL_AUTH_DATA_TO_STORE, 
      payload: {accessToken: accessToken, user: user}
    }),
  }
}

export const baseURL = 'http://cubin.com.vn';

export default connect(mapStateToProps, mapDispatchToProps)(App);
