import React from 'react';

import ClientHeader from './ClientHeader';
import ClientCoreView from './ClientCoreView';
import Container from './Container';
import { ClientViewWraper, CoreWraper} from './CommonUI';



class NotLoggedInClientView extends React.Component {
    render() {
        return (
            <ClientViewWraper>
                <ClientHeader />
                <CoreWraper>
                    <Container>
                        <ClientCoreView history={this.props.history}/>
                        <div style={{width: '100%', height: '1px'}}></div>
                    </Container>
                </CoreWraper>


                {/* <div style={{ width: '1000px', margin: '0 auto', display: 'flex' }}>
                    <div style={{width: '250px', height:'500px', border: '1px solid #cca'}}></div>
                    
                </div> */}

            </ClientViewWraper>

        )
    }
}

export default NotLoggedInClientView;