import React, { Component } from 'react';
import { connect } from 'react-redux';
//import styled from 'styled-components';
import { Layout, Avatar, Menu, Modal } from 'antd';
import logo from '../../assets/logo.png';
import { NavLink, Switch, Route } from 'react-router-dom';
import { UserOutlined, FormOutlined, SnippetsOutlined, LogoutOutlined, DashboardOutlined, OrderedListOutlined } from '@ant-design/icons';

import * as actions from '../../store/actions';

import Booking from './Booking';
import AddBooking from './AddBooking';
import Booked from './Booked';
import UserManagment from './UserManagment';

const { Header, Footer, Sider, Content } = Layout;
const { confirm } = Modal;

class AdminView extends Component {

    clearLocalStorage = () => {
        console.log("Props: ", this.props);
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        this.props.notifyLocalAuthCleared();
    }

    showLogoutConfirm = () => {
        let self = this;
        confirm({
            title: 'Bạn chắc chắn muốn đăng xuất?',
            onOk() {
                self.clearLocalStorage();
            },
            onCancel() {
                console.log("Logout cancelled");
            }
        });
    }

    render() {
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Sider style={{ height: 'unset' }}>
                    <div className='logo'>
                        <NavLink to="/">
                            <img src={logo} alt="LOGO" style={{ width: '150px', margin: '20px auto', display: 'block' }} />
                        </NavLink>
                    </div>
                    <Menu theme='dark' mode='inline' defaultSelectedKeys={['1']}>
                        <Menu.Item key="1">
                            <NavLink to="/dashboard">
                                <DashboardOutlined />
                                <span>Bảng điều khiển</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <NavLink to="/booking">
                                <SnippetsOutlined />
                                <span>Lập lịch tư vấn</span>
                            </NavLink>
                        </Menu.Item>
                        <Menu.Item key="3">
                            <NavLink to="/booked">
                                <OrderedListOutlined />
                                <span>Người đặt lịch</span>
                            </NavLink>
                        </Menu.Item>
                        {/* <Menu.Item key="4">
                            <NavLink to="/waiting">
                                <FormOutlined />
                                <span>Chờ duyệt</span>
                            </NavLink>
                        </Menu.Item> */}
                        {/* <Menu.Item key="2">
                            <NavLink to="/add-booking">
                                <SnippetsOutlined />
                                <span>Lập lịch tư vấn</span>
                            </NavLink>
                        </Menu.Item> */}
                        
                        <Menu.Item key="7">
                            <NavLink to="/users">
                                <UserOutlined />
                                <span>Người quản trị</span>
                            </NavLink>
                        </Menu.Item>
                        {/* <Menu.Item key="5">
                            <NavLink to="/setting">
                                <FormOutlined />
                                <span>Cài đặt</span>
                            </NavLink>
                        </Menu.Item> */}
                        <Menu.Item key="8" onClick={this.showLogoutConfirm}>
                            <LogoutOutlined />
                            <span>Đăng xuất</span>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout>
                    <Header style={{ backgroundColor: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '0 16px' }}>
                        <Avatar icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} />
                    </Header>
                    <Layout>
                        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                            <div className="site-layout-background" style={{ padding: 24, backgroundColor: '#fff' }}>
                                <Switch>
                                    <Route path="/booking" exact component={Booking} />
                                    <Route path="/booked" exact component={Booked} />
                                    <Route path="/add-booking" exact component={AddBooking} />
                                    <Route path="/users" exact component={UserManagment} />
                                    <Route path="/" component={Booking} />
                                </Switch>
                            </div>
                        </Content>
                        <Footer style={{ textAlign: 'center' }}>Bản quyền 2020 - Du học Right way</Footer>
                    </Layout>

                </Layout>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        notifyLocalAuthCleared: () => { dispatch({ type: actions.CLEAR_LOCAL_AUTH_DATA }) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminView);