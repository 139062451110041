import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { message } from 'antd';
import axios from 'axios';

// import Container from './Container';
import ShadowBox from './ShadowBox';
import ClientDateBar from './ClientDateBar';
import DateDivider from './DateDivider';
import AvailableConsultantDayBlock from './AvailableConsultantDayBlock';
import * as colors from './colors';
import LoadingBox from './LoadingBox';

import './Styles.css'

const Wrapper = styled.div`
width: 100%;
background-color: ${colors.colorLightPurple};
padding: 30px 0 0 0;
`;

let h = window.innerHeight - 64;
const PaddingBox = styled.div`
    height: ${h + 'px'}
`;

let h2 = window.innerHeight - 300;
const NoDataWrapper = styled.div`
    padding-top: 64px;
    height: ${h2 + 'px'}
`;

const Nothing = styled.h2`
    text-align: center;
`;

class ClientCoreView extends React.Component {

    state = {
        loading: true,
        data: null,
        selectedDate: null
    }

    onDateChanged = (selectedDate) => {
        this.setState({selectedDate: selectedDate});
        this.loadData(selectedDate);
    } 

    componentDidMount = () => {
        this.loadData();
    }

    loadData = (date)=>{
        this.setState({loading: true});
        let params = new URLSearchParams();
        if(date){
            params.append("date", date.format('YYYY-MM-DD'));
        }
        axios.get('/api/getListBookingforStudent.php', {params: params})
        .then(resp => {
            console.log(resp.data);
            this.setState({loading: false, data: resp.data.data});
        })
        .catch(e => {
            this.setState({loading: false});
            message.error("Đã xảy ra lỗi. Xin vui lòng thử lại.");
        })
    }

    getNoDataView = () => {
        return (
            <NoDataWrapper>
                <Nothing>
                    Rất tiếc, không có khung giờ tư vấn nào trống.
                    <br />
                    Xin vui lòng chọn 1 ngày khác!
                </Nothing>
            </NoDataWrapper>
        );
    }

    render() {

        let blocks;
        if(!this.state.data){
            blocks = this.getNoDataView();
        } else {
            blocks = this.state.data.map((consultant, index) => {
                return <AvailableConsultantDayBlock key={index} {...consultant} history={this.props.history}/>
            })
        }

        return (
            this.state.loading ? <PaddingBox><LoadingBox/> </PaddingBox>
            :
            <Wrapper>
                    <ShadowBox>
                        SearchBox Here
                    </ShadowBox>
                    <ClientDateBar onDateChanged={this.onDateChanged} />

                    <DateDivider />

                    {blocks}
            </Wrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedDate: state.selectedDate
    }
}

const mapDispatchToProps = dispatch =>{
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientCoreView);