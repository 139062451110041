import React from 'react';
import styled from 'styled-components';
import { Form, Input, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../store/actions';

//import * as sizes from './sizes';
import logo from '../assets/logo.png';
import LoadingModal from './LoadingModal';

const Laptop = styled.div`
    width: 100%;
    height: 0;
    display: flex;
    justify-content: center;
    background-color: #E2F1FA;
    @media (min-width: 768px){
        height: 100vh;
    }
`;

const LapTopBox = styled.div`
    display: none;
    width: 400px;
    height: 0;
    margin: auto;
    padding: 0 15px;
    background-color: #fff;
    box-shadow: 0 0 1px 0px rgba(0,0,0,0.3);
    border-radius: 1px;
    @media (min-width: 768px){
        display: block;
        height: 85%;
    }
`;

const OneControl = styled.div`
    margin-top: 10px;
`;

const LogoWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin: 50px 0 20px 0;
`;

const Guide = styled.p`
    width: 100%;
    text-align: center;
    margin: 40px 0;
`;

const WideButton = styled(Button).attrs(
    props => (
        { type: props.type, htmlType: props.htmlType }
    )
)`
    width: 100%;
`;

const Mobile = styled.div`
    width: 100%;
    padding: 30px;
    @media(min-width: 768px){
        display: none;
    }
`;

/** Wrappers for forgotten password and register */
const LinksWrapper = styled.div`
    width: 100%;
    text-align: center;
    margin: 40px 0;
`;

const LinkCover = styled.span`
    display: block;
    margin: 10px auto;
`;

const Space20 = styled.div`
height: 20px;
`;

class Login extends React.Component {
    showLoginFailed = (msg) => {
        message.error(msg);
    }

    saveDataToStorage = data => {
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('user', JSON.stringify(data.user));
    }

    setAxiosDefaultAccessToken = (accessToken) => {
        axios.defaults.headers.common['Authorization'] = accessToken;
    }

    onFinish = (values) => {
        const params = new URLSearchParams();
        params.append("email", values.username);
        params.append("password", values.password);


        console.log("Form values: ", values);
        axios.post('/api/checkUser.php', params)
            .then(resp => {
                console.log("CheckUser: ", resp);
                if(resp.data.status === 1){
                    let data = {
                        accessToken: resp.data.accessToken,
                        user: resp.data.data
                    }
                    
                    this.saveDataToStorage(data);
                    this.props.notifyLoginSuccess(data);
                    this.props.history.push("/");
                    this.setAxiosDefaultAccessToken(data.accessToken);

                    message.success("Đăng nhập thành công");
                } else {
                    this.showLoginFailed(resp.data.message);
                }
            })
            .catch(e => {
                console.error(e);
            })
    }

    render() {
        // let { getFieldDecorator } = this.props.form;
        let form = this.props.form;

        const usernameRules = [
            {
                required: true,
                message: "Vui lòng điền tên đăng nhập hoặc số điện thoại"
            }
        ];

        const passwordRules = [
            {
                required: true,
                message: "Vui lòng nhập mật khẩu"
            }
        ];

        const initialValues = {
            username: 'congquyet08042012@gmail.com',
            password: '123'
        };

        const coreContent = (
            <React.Fragment>
                <LogoWrapper>
                    <img src={logo} alt='LOGO' style={{ width: '200px', margin: '10px auto' }} />
                </LogoWrapper>
                <Guide>Vui lòng đăng nhập để sử dụng dịch vụ</Guide>

                <Form form={form} onFinish={this.onFinish} initialValues={initialValues}>
                    <OneControl>
                        <Form.Item name='username' rules={usernameRules}>
                            <Input placeholder='Tên đăng nhập hoặc số điện thoại' />
                        </Form.Item>
                    </OneControl>
                    <OneControl>
                        <Form.Item name='password' rules={passwordRules}>
                            <Input type='password' placeholder='Mật khẩu' />
                        </Form.Item>
                    </OneControl>
                    <OneControl>
                        <WideButton type='primary' htmlType='submit'>Đăng nhập</WideButton>
                    </OneControl>
                </Form>

                <LinksWrapper>
                    <LinkCover>
                        <Link to="/reset-password">Bạn quên mật khẩu?</Link>
                    </LinkCover>
                    <LinkCover>
                        <Link to="/register">Tạo tài khoản mới</Link>
                    </LinkCover>
                    <div style={{ color: 'rgba(0,0,0,.4)' }}>Hoặc</div>
                    <LinkCover>
                        <Link to="/">Quay về trang chủ</Link>
                    </LinkCover>
                </LinksWrapper>
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Laptop>
                    <LapTopBox>
                        {coreContent}
                        <Space20 />
                    </LapTopBox>
                </Laptop>
                <Mobile>
                    {coreContent}
                </Mobile>
                <LoadingModal />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {
        notifyLoginSuccess: loginResponseData => dispatch({type: actions.LOGIN_SUCCESS, payload: loginResponseData})
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);