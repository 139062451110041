import React from 'react';
import styled from 'styled-components';

import ClientHeader from './ClientHeader';
import ClientCoreView from './ClientCoreView';
// import Container from './Container';
import LeftMenuContent from './LeftMenuContent';
import * as colors from './colors';


const ClientViewWraper = styled.div`
    width: 100%;
`;

const BodyWrapper = styled.div`
    width: 100%;
    background-color: ${colors.colorLightPurple};
`;

const ContentWrapper = styled.div`
    width: 100%;
    background-color: ${colors.colorLightPurple};
    display: flex;
    @media (min-width: 1024px){
        width: 1000px;
        margin: 0 auto;
    }
`;

//box-shadow: 2px 0 6px 1px rgba(0,0,0,0.3);
const LeftMenuWrapper = styled.div`
    width: 250px;
    display: none;
    @media(min-width: 1024px){
        display: block;
        
    }
`;

const CoreWrapper = styled.div`
    width: 100%;
    padding: 0 10px;   
    margin: 0 auto;
    @media(min-width: 1024px){
        width: 750px;
    }
`;

class LoggedInClientView extends React.Component {
    render() {
        return (
            <ClientViewWraper>
                <ClientHeader />
                <BodyWrapper>
                    <ContentWrapper>
                        <LeftMenuWrapper>
                            <LeftMenuContent></LeftMenuContent>
                        </LeftMenuWrapper>
                        <CoreWrapper>
                            <ClientCoreView />
                        </CoreWrapper>
                    </ContentWrapper>
                </BodyWrapper>
            </ClientViewWraper>
        );
    }
}

export default LoggedInClientView;