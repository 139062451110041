import React from 'react';
import styled from 'styled-components';
import { Button, Table, message, Modal, Form, Input, InputNumber } from 'antd';
import axios from 'axios';
import Loading from '../ui/Loading';


const HeaderButtonWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
`;

class UserManagment extends React.Component {

    state = {
        initializing: true,
        users: [],
        showModal: false,
        modalTitle: '',
        submiting: false,
    }

    loadUserList = () => {
        axios.get('/api/getUser.php')
        .then(resp => {
            console.log('getUser: ', resp.data);
            this.setState({ users: resp.data.data });
        })
        .catch(e => {
            message.error("Không thể lấy được dữ liệu");
            console.error(e);
        })
        .finally(() => {
            this.setState({ initializing: false });
        })
    }
    componentDidMount = () => {
        this.loadUserList();
    }

    showModalForAddNew = () => {
        this.setState({ showModal: true, modalTitle: 'Thêm người dùng' });
    }

    modalCancel = () => {
        this.setState({ showModal: false, modalTitle: '' });
    }

    formOnFinish = (values) => {
        console.log("Form values: ", values);
        this.setState({submiting: true});
        const params = new FormData();
        params.append("typeFunction", "INSERT");
        params.append("fullName", values.fullName);
        params.append("typeUser", 0); // For consultant
        params.append("email", values.email);
        params.append("password", values.password);
        params.append("phone", values.phone);
        params.append("address", values.address);
        params.append("rating", values.rating ? values.rating : 0);
        params.append("ratingCount", values.ratingCount ? values.ratingCount : 0);

        axios.post('/api/funcUser.php', params)
        .then( resp => {
            const data = resp.data;
            if(data.status === 1){
                message.success('Tạo người dùng thành công');
                this.setState({submiting: false, showModal: false});
                this.loadUserList();
            } else {
                if(data.message) {
                    message.error(data.message);
                } else {
                    message.error('Đã có lỗi xảy ra, vui lòng thử lại');
                }
            }
        })
        .catch(e => {
            message.error('Đã có lỗi xảy ra, vui lòng thử lại');
            console.error(e);
        })
        .finally(()=> {
            this.setState({submiting: false});
        })
    }

    render() {

        const columns = [
            {
                title: 'STT',
                render: (t, r, i) => (i + 1),
                width: '5%'
            },
            {
                title: 'Họ tên',
                render: (t, r, i) => (r.fullName)
            },
            {
                title: 'Email',
                render: (t, r, i) => (r.email)
            },
            {
                title: 'Điện thoại',
                render: (t, r, i) => (r.phone)
            },
            {
                title: 'Địa chỉ',
                render: (t, r, i) => (r.address)
            },
            {
                title: 'Đánh giá',
                render: (t, r, i) => {
                    let rating, ratingCount;
                    try {
                        rating = Number(r.rating);
                    } catch (e) {
                        rating = 0;
                    }
                    try {
                        ratingCount = Number(r.ratingCount);
                    } catch (e2) {
                        ratingCount = 0;
                    }
                    return rating + " (" + ratingCount + " lượt)";
                }
            },
        ]

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
        };

        const initialValues = {
            rating: 5.0,
            ratingCount: 100
        }


        return (
            <div style={{ minHeight: '500px' }}>
                <h2>NGƯỜI DÙNG</h2>
                {
                    this.state.initializing ?
                        <Loading />
                        : (
                            <React.Fragment>
                                <HeaderButtonWrapper>
                                    <Button type='primary'
                                        onClick={this.showModalForAddNew}>Thêm mới</Button>
                                </HeaderButtonWrapper>

                                <Table
                                    rowKey={row => row.id}
                                    columns={columns}
                                    dataSource={this.state.users}

                                    pagination={false} />

                                <Modal
                                    title={this.state.modalTitle}
                                    destroyOnClose={true}
                                    onCancel={this.modalCancel}
                                    footer={null}
                                    visible={this.state.showModal}>
                                    <Form {...layout} onFinish={this.formOnFinish} initialValues={initialValues}>
                                        <Form.Item 
                                            name='fullName' label='Họ tên' 
                                            rules={[{required: true, message: 'Vui lòng điền họ tên'}]}>
                                            <Input />
                                        </Form.Item>

                                        <Form.Item 
                                            name='email' label='Email'
                                            rules={[{required: true, message: 'Vui lòng điền email'}]}>
                                            <Input type='email' />
                                        </Form.Item>

                                        <Form.Item 
                                            name='password' label='Mật khẩu'
                                            rules={[{required: true, message: 'Vui lòng điền mật khẩu'}]}>
                                            <Input type='password' />
                                        </Form.Item>

                                        <Form.Item 
                                            name='phone' label='Số điện thoại'
                                            rules={[{required: true, message: 'Vui lòng điền số điện thoại'}]}>
                                            <Input />
                                        </Form.Item>

                                        <Form.Item name='address' label='Địa chỉ'
                                            rules={[{required: true, message: 'Vui lòng điền địa chỉ'}]}>
                                            <Input />
                                        </Form.Item>

                                        <Form.Item 
                                            name='rating' label='Đánh giá (sao)'>
                                            <InputNumber min={0} max={5} step={0.1} />
                                        </Form.Item>

                                        <Form.Item name='ratingCount' label='Số lượt đánh giá'>
                                            <InputNumber min={1} />
                                        </Form.Item>

                                        <Form.Item {...tailLayout}>
                                            <Button type='primary' htmlType='submit' loading={this.state.submiting}>
                                                Lưu
                                            </Button>
                                        </Form.Item>

                                    </Form>
                                </Modal>
                            </React.Fragment>

                        )
                }

            </div>
        );
    }
}

export default UserManagment;