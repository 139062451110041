import * as actions from './actions';

const initialState = {
    startingUp: true,
    isLoggedIn: false,
    userType: null,
    accessToken: null,
    user: {
        fullName: null,
        userId: null,
        email: null
    }
};

const reducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case actions.STARTING_UP_DONE:
            newState = { ...state };
            newState.startingUp = false;
            return newState;

        case actions.PUT_LOCAL_AUTH_DATA_TO_STORE:
        case actions.LOGIN_SUCCESS:
            newState = { ...state };
            newState.accessToken = action.payload.accessToken;
            newState.user = action.payload.user;
            newState.isLoggedIn = true;
            let userType = 'client';
            //if (action.payload.user.userType === 'admin' || action.payload.user.userType === 'superAdmin') {
            //    userType = 'admin';
            //}
            userType = action.payload.user.userType;
            newState.userType = userType;
            return newState;

        case actions.CLEAR_LOCAL_AUTH_DATA:
            newState = { ...state };
            newState.accessToken = null;
            newState.user = null;
            newState.isLoggedIn = false;
            return newState;

        case actions.SELECT_BLOCK:
            newState = { ...state }
            newState['selectedBlock'] = action.payload.block;
            newState['selectedConsultantInfo'] = action.payload.consultantInfo;
            return newState;

        case actions.ORDER_CREATED:
            newState = { ...state }
            newState['orderId'] = action.payload.orderId;
            return newState;

        case actions.LOGOUT:
            newState = {...state}
            newState['isLoggedIn'] = false;
            return newState;

        case actions.DATE_CHANGED:
            newState = {...state};
            newState['selectedDate'] = action.payload;
            return newState;

        default:
            return { ...state };
    }
}

export default reducer;