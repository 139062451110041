// import React, { Component } from 'react';
import styled from 'styled-components';

const BackDrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0,0,0,0.85);
`;

export default BackDrop;