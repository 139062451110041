import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
width: 100%;
border-top: 1px solid #ccc;
`;

const DateText = styled.p`
display: block;
margin: -13px auto 0 auto;
background-color: #f6f8fc;
width: 66px;
text-align: center;
`;
class DateDivider extends React.Component {
    render(){

        let text = this.props.text ? this.props.text : 'N/A';

        return(
            <Wrapper>
                <DateText>{text}</DateText>
            </Wrapper>
        );
    }
}

export default DateDivider;