import React from 'react';
import axios from 'axios';
// import styled from 'styled-components';
import { Form, Input, DatePicker, InputNumber, Button, TimePicker, Checkbox, message } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { SaveOutlined } from '@ant-design/icons'

const labelColLgSpan = 2;

const layout = {
    labelCol: {
        sm: { span: 8 },
        lg: { span: labelColLgSpan }
    },
    wrapperCol: {
        sm: { span: 16 },
        lg: { span: 20 }
    }

}

const tailLayout = {
    wrapperCol: {
        sm: {
            offset: 0,
            span: 24
        },
        lg: {
            offset: labelColLgSpan,
            span: 20
        }
    }
}

class AddBooking extends React.Component {

    state = {
        loading: true,
        submiting: false,
        checked: [true, true, true, true, true, true, true, true],
        initialValues: {
            date: moment(),
            price: 85,
            available_0: true,
            timeStart_0: moment(),
            timeEnd_0: moment(),
            available_1: true,
            timeStart_1: moment('09:00', 'HH:mm'),
            timeEnd_1: moment()
        }
    }

    onFinish = (values) => {
        console.log("Form values: ", values);
        const blocks = [];
        for (let i = 0; i < 7; i++) {
            if (values['available_' + i]) {
                let bl = {
                    block: i + 1,
                    timeStart: values['timeStart_' + i].format('YYYY-MM-DD HH:mm:ss'),
                    timeEnd: values['timeEnd_' + i].format('YYYY-MM-DD HH:mm:ss'),
                }
                blocks.push(bl);
            }
        }
        // Form post data:
        const data = {
            consultancyType: values.consultancyType,
            date: values.date.format('YYYYMMDD'),
            price: values.price,
            data: blocks
        }
        console.log("data: ", data);
        axios.post('/api/addBlock.php', data)
            .then(resp => {
                message.success(resp.data.message);
                this.setState({ submiting: false });
                this.props.history.push("/booking");
            })
            .catch(e => {
                this.setState({ submiting: false });
            }
            )
    }


    /**
     * serverBlocks: [
        {
            "block": "1",
            "timeStart": "2020-04-03 08:00:00",
            "timeEnd": "2020-04-03 08:45:00",
            "status": "0"
        },
    ]
     */
    getBlocks = serverBlocks => {
        let a = [0, 1, 2, 3, 4, 5, 6, 7].map(n => {

            if (serverBlocks.length > 0) {
                for (let i = 0; i < serverBlocks.length; i++) {
                    let blockNumber = n + 1;
                    let serverBlockNumber = Number(serverBlocks[i].block);
                    if (blockNumber === serverBlockNumber) {
                        return {
                            block: n,
                            timeStart: moment(serverBlocks[i].timeStart, 'YYYY-MM-dd HH:mm:ss'),
                            timeEnd: moment(serverBlocks[i].timeEnd, 'YYYY-MM-dd HH:mm:ss'),
                            status: Number(serverBlocks[i].status),
                            available: 1
                            // status: 1
                        }
                    }
                }
            }

            /**
             * If a preset block could not be found in API response,
             * we will fill in default values
             * 0: 08:00 - 08: 45
             * 1: 09:00 = 09: 45
             * 2: 10:00 = 10: 45
             * 3: 11:00 = 11: 45
             *
             * 4: 13:00 - 13:45
             * 5: 14:00 - 14:45
             * ...
             * 
             */
            let timeStart;
            let timeEnd;
            let theDay = this.state.initialValues.date;
            let theDayStr = theDay.format('YYYY/MM/DD ');
            if (n < 4) {
                let s;
                if (n + 8 < 10) {
                    s = theDayStr + '0' + (n + 8) + ':00';
                } else {
                    s = theDayStr + (n + 8) + ':00';
                }
                console.log('n = ' + n + ', s = ' + s);
                timeStart = moment(s, 'YYYY/MM/DD HH:mm');

                let end;
                if (n + 8 < 10) {
                    end = theDayStr + '0' + (n + 8) + ':45';
                } else {
                    end = theDayStr + (n + 8) + ':45';
                }

                timeEnd = moment(end, 'YYYY/MM/DD HH:mm');
            } else {
                let s = theDayStr + (n + 9) + ':00';
                let end = theDayStr + (n + 9) + ':45';
                timeStart = moment(s, 'YYYY/MM/DD HH:mm');
                timeEnd = moment(end, 'YYYY/MM/DD HH:mm');
            }
            let defaultBlock = {
                block: n,
                timeStart: timeStart,
                timeEnd: timeEnd,
                status: 0
            };

            // console.log("Now return default", defaultBlock);
            return defaultBlock;
        });

        // console.log("a: ", a);
        return a;
    }

    processResp = resp => {
        let data = resp.data.data;
        console.log('resp.data.data: ', data);
        let initialValues2 = { ...this.state.initialValues }
        initialValues2.date = moment(); // TODO: get from API
        if (!data || typeof (data) === 'string') {
            data = [];
        }
        const blocks = this.getBlocks(data);
        blocks.forEach((el, index) => {
            initialValues2['timeStart_' + index] = el.timeStart;
            initialValues2['timeEnd_' + index] = el.timeEnd;
            initialValues2['available_' + index] = el.available;
            initialValues2['status_' + index] = el.status === 1 ? true : false;
        })
        initialValues2.date = this.state.initialValues.date;
        initialValues2.consultancyType = resp.data.consultancyType;
        initialValues2.price = resp.data.price;
        this.setState({
            initialValues: initialValues2,
            // blocks: blocks,
            loading: false
        });
    }

    componentDidMount = () => {
        this.loadData(moment());
    }

    loadData = (momentDate) => {
        const params = new URLSearchParams();
        const dateStr = momentDate.format('YYYYMMDD');
        // console.log("Date String: ", typeof (dateStr));
        params.append('date', dateStr);
        params.append('accessToken', this.props.accessToken);

        axios.post('/api/getListBooking.php', params)
            .then(resp => {
                this.processResp(resp);
            })
            .catch(error => {
                console.log(error);
                message.error('Đã có lỗi xảy ra! Vui lòng thử lại.');
            });
    }

    onCheckedChange = num => {
        let newChecked = [...this.state.checked];
        newChecked[num] = !newChecked[num];
        this.setState({ checked: newChecked });
        // console.log("Checkbox number " + num + " changed. New checked state: ", newChecked);
    }

    /**
     * dateValue is a moment object
     */
    onDateChange = dateValue => {
        this.setState({ loading: true, initialValues: { date: dateValue } });
        this.loadData(dateValue);
    }


    render() {

        return (
            <React.Fragment>
                <h2>LẬP LỊCH TƯ VẤN</h2>
                {
                    this.state.loading ? <p>Loading...</p>
                        :
                        <Form {...layout}
                            onFinish={(v) => {
                                this.setState({ submiting: true });
                                this.onFinish(v);
                            }}
                            initialValues={this.state.initialValues}>
                            <Form.Item name="consultancyType" label="Kiểu tư vấn"
                                rules={[{ required: true, message: 'Vui lòng điền kiểu tư vấn' }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item style={{ marginBottom: '0' }} label='Giá tiền' >
                                <Form.Item name="price" style={{ display: 'inline-block', width: 'calc(50%-12px)' }}>
                                    <InputNumber
                                        defaultValue={1}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        rules={[
                                            { required: true, message: 'Vui lòng điền giá tiền' }
                                        ]}
                                    />
                                </Form.Item>
                                <span style={{ display: 'inline-block', width: '68px', textAlign: 'right', height: '32px', verticalAlign: 'sub', paddingRight: '6px' }}> Ngày: </span>
                                <Form.Item name="date" style={{ display: 'inline-block', width: 'calc(50%-12px)' }}>
                                    <DatePicker placeholder='Ngày' help='Ngày tư vấn' format='DD/MM/YYYY' onChange={this.onDateChange} />
                                </Form.Item>
                            </Form.Item>

                            {
                                [0, 1, 2, 3, 4, 5, 6, 7].map(num => (
                                    <Form.Item style={{ marginBottom: '0' }} label={'Khung giờ ' + (num + 1)} key={num}>
                                        <Form.Item name={'timeStart_' + (num)} style={{ display: 'inline-block', width: 'calc(50%-12px)' }}
                                            rules={[{ required: this.state.checked[num], message: 'Xin hãy chọn giờ bắt đầu' }]}>
                                            <TimePicker placeholder='Giờ bắt đầu' format='HH:mm' />
                                        </Form.Item>
                                        <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}> - </span>
                                        <Form.Item name={'timeEnd_' + (num)} style={{ display: 'inline-block', width: 'calc(50%-12px)' }}
                                            rules={[{ required: false, message: 'Xin hãy chọn giờ kết thúc' }]}>
                                            <TimePicker placeholder='Giờ kết thúc' format='HH:mm' />
                                        </Form.Item>
                                        <span style={{ display: 'inline-block', width: '24px', textAlign: 'center' }}>  </span>
                                        <Form.Item name={'available_' + num} valuePropName='checked' style={{ display: 'inline-block', width: 'calc(50%-12px)' }}>
                                            <Checkbox onChange={() => { this.onCheckedChange(num) }}>Có làm việc</Checkbox>
                                        </Form.Item>
                                        <Form.Item name={'status_' + num} valuePropName='checked' style={{ display: 'inline-block', width: 'calc(50%-12px)' }}>
                                            <Checkbox disabled>Đã đặt</Checkbox>
                                        </Form.Item>
                                    </Form.Item>
                                ))
                            }



                            <Form.Item {...tailLayout} style={{ marginTop: '16px' }}>
                                <Button loading={this.state.submiting} icon={<SaveOutlined />} type='primary' htmlType='submit'>Lưu dữ liệu</Button>
                            </Form.Item>
                        </Form>
                }

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        accessToken: state.accessToken
    }
}

export default connect(mapStateToProps)(AddBooking);