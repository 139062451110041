import React from 'react';
import { connect } from 'react-redux';
import { Typography, DatePicker, Checkbox, Radio, message } from 'antd';
import { Form, Input, Button, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import ClientHeader from './ClientHeader';

import Container from './Container';
import { ClientViewWraper, CoreWraper } from './CommonUI';
import ShadowBox from './ShadowBox';
import styled from 'styled-components';
import moment from 'moment';
import axios from 'axios';
import * as actions from '../store/actions';



// const { Title } = Typography;

const PageTitle = styled.h2`
    padding: 20px 0;
    text-align: center;
    font-weight: 700;
`;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const { TextArea } = Input;

/**
 * Màn hình cho khách điền thông tin để đặt lịch
 */
class BookingPreparation extends React.Component {

    state = {
        fileList: [],
        submiting: false
    }

    onFormFinish = values => {
        this.setState({ submiting: true });
        const { fileList } = this.state;
        const { block } = this.props;
        
        console.log("Form finish: block: ", block);

        const formData = new FormData();
        formData.append('fileCv', fileList[0])
        formData.append('phone', values.phone);
        formData.append('fullName', values.fullName);
        formData.append('email', values.email);
        formData.append('address', values.address);
        formData.append('paymentType', values.paymentType);
        formData.append('note', values.note);
        formData.append('idBlockBooking', block.id);

        axios.post('/api/addOrder.php', formData)
            .then(resp => {
                console.log('add order resp: ', resp.data);
                const orderId = resp.data['getOrderID'];
                this.props.notifyOrderCreated(orderId);
                setTimeout(() => {
                    this.props.history.push('/thanh-toan');
                }, 300);
                
            })
            .catch(e => {
                console.error(e);
                message.error('Rất tiếc, đã có lỗi xảy ra khi xử lý yêu cầu của bạn. Vui lòng thử lại!');
            })
            .finally(() => {
                this.setState({ submiting: false });
            })
    }

    beforeUpload = (file) => {
        console.log("beforeUpload: file: ", file);
        this.setState({ fileList: [file] });
        return false;
    }

    onRemove = file => {
        console.log("onRemove: file: ", file);
        const index = this.state.fileList.indexOf(file);
        const newFileList = this.state.fileList.slice();
        newFileList.splice(index, 1);
        this.setState({ fileList: newFileList });
    }



    render() {
        const { consultantInfo } = this.props;
        console.log("state: ", this.state.fileList);

        let initialValues = {};
        if (consultantInfo) {
            initialValues = {
                consultantName: consultantInfo.consultantName,
                consultancyType: consultantInfo.consultancyType,
                date: moment()
            }
        }

        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };


        return (
            <ClientViewWraper>
                <ClientHeader />
                <CoreWraper>
                    <Container>
                        <div style={{height: '10px'}}></div>
                        <ShadowBox>
                            {
                                consultantInfo ?
                                    <Form {...formItemLayout} onFinish={this.onFormFinish} initialValues={initialValues}>
                                        <PageTitle>ĐẶT LỊCH TƯ VẤN</PageTitle>
                                        <h4>Thông tin của bạn</h4>
                                        <Form.Item name='fullName' label="Họ tên" rules={[{ required: true, message: 'Vui lòng điền họ tên' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name='phone' label="Số điện thoại" rules={[{ required: true, message: 'Vui lòng điền số điện thoại' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name='email' label="Email" rules={[{ required: true, message: 'Vui lòng điền email' }]}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name='address' label="Câu hỏi của bạn là gì?" rules={[{ required: true, message: 'Vui lòng điền địa chỉ' }]}>
                                            <TextArea placeholder="Vui lòng ghi thông tin chi tiết"/>
                                        </Form.Item>
                                        <Form.Item name='fileCv' label="File CV"
                                            valuePropName='fileList'
                                            rules={[{ required: false, message: 'Vui lòng đính kèm file CV của bạn' }]}>
                                            <Upload
                                                accept=".png,.jpg,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                onRemove={this.onRemove}
                                                showUploadList={false}
                                                beforeUpload={this.beforeUpload}
                                                fileList={this.state.fileList}>
                                                <Button>
                                                    <UploadOutlined /> Chọn file
                                                </Button>
                                                {
                                                    this.state.fileList.length > 0 ?
                                                        <span style={{ marginLeft: '10px', color: '#00a514' }}>
                                                            {this.state.fileList[0].name}
                                                        </span>
                                                        : null
                                                }
                                            </Upload>
                                        </Form.Item>

                                        <h4>Thông tin tư vấn viên</h4>
                                        <Form.Item name='consultantName' label='Người tư vấn' style={{ marginBottom: '10px' }}>
                                            <Input disabled />
                                        </Form.Item>
                                        <Form.Item name='consultancyType' label='Loại tư vấn' style={{ marginBottom: '10px' }}>
                                            <Input disabled />
                                        </Form.Item>
                                        <Form.Item name='date' label='Ngày' style={{ marginBottom: '10px' }}>
                                            <DatePicker format='DD/MM/YYYY' disabled />
                                        </Form.Item>

                                        <h3>Hình thức thanh toán</h3>
                                        <Form.Item
                                            rules={[{ required: true, message: 'Vui lòng lựa chọn một hình thức thanh toán' }]}
                                            name='paymentType' label='Hình thức thanh toán' style={{ marginBottom: '10px' }}>
                                            <Radio.Group>
                                                <Radio style={radioStyle} value={'paypal'}>
                                                    Paypal/CreditCard
                                                </Radio>
                                                <Radio style={radioStyle} value={'ck'} disabled>
                                                    Chuyển khoản ngân hàng
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>

                                        <Form.Item name='note' label='Ghi chú' style={{ marginBottom: '10px' }}>
                                            <TextArea lines={4} />
                                        </Form.Item>
                                        <Form.Item {...tailFormItemLayout}>
                                            <Button loading={this.state.submiting} type='primary' htmlType='submit'>
                                                Tiếp tục
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    : <p>Bạn phải chọn một khung giờ trước khi vào đây</p>
                            }

                        </ShadowBox>
                        <div style={{ height: '20px' }}></div>

                    </Container>
                </CoreWraper>
            </ClientViewWraper>
        )
    }
}


const mapStateToProps = state => {
    return {
        consultantInfo: state.selectedConsultantInfo,
        block: state.selectedBlock,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        notifyOrderCreated: orderId => { dispatch({ type: actions.ORDER_CREATED, payload: { orderId: orderId } }) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingPreparation);